<template>
  <div class="box has-padding-y-300">
    <loading v-if="$_.isEmpty($_.omit(task, ['_posts']))" />
    <div v-else>
      <b-notification
        v-if="
          $task.userIsAgent &&
            $task.siteIsSubscribed === false &&
            $_.get($task, `plan.ref`)
        "
        type="is-danger"
        :closable="false"
        class="has-margin-bottom-50"
        >Site is no longer subscribed.</b-notification
      >
      <div class="task-participants">
        <avatar
          v-for="participant in task.participants"
          :key="participant"
          :name="$_.get(task, `participantsData.${participant}.name`)"
          :avatar="$_.get(task, `participantsData.${participant}.avatarUrl`)"
          :size="60"
        />
      </div>

      <div class="has-text-centered has-margin-y-100">
        <p>
          <strong>Task {{ task.reference }}</strong>
        </p>
        <p>
          <a
            :class="{
              'is-size-7': true,
              'has-text-grey': !task.subject,
              'has-text-dark': task.subject
            }"
            @click="$taskMethods.updateSubject"
          >
            <component :is="task.subject ? 'em' : 'span'">
              <u>{{
                task.subject ? `'${task.subject}'` : `Add task subject`
              }}</u>
            </component>
          </a>
        </p>
        <p class="is-size-7 has-text-grey">
          {{ task.participants.length }} participant(s) &bull; Opened
          {{ $moment(task.dateCreated.toDate()).from() }}
        </p>
      </div>

      <div class="buttons is-centered">
        <!-- Close Task -->
        <button
          v-if="$task.canBeClosed"
          type="button"
          class="button is-stretched"
          @click="$taskMethods.closeTask"
        >
          <span class="is-size-6">Close Task</span>
        </button>
        <!-- ReOpen Task -->
        <button
          v-if="$task.canBeReopened"
          type="button"
          class="button is-stretched"
          :class="{ 'is-loading': processing.opening }"
          :disabled="processing.opening"
          @click="reOpenTask"
        >
          Re-open task
        </button>
      </div>

      <table class="table">
        <!-- Status -->
        <tr>
          <td>Status</td>
          <td colspan="2">
            <status-label
              :status="$store.getters['tasks/task/status'](task.status)"
            />
          </td>
        </tr>
        <!-- Task Type -->
        <tr>
          <td>Type</td>
          <td colspan="2">
            <a
              v-if="$task.userIsAgent"
              class="is-size-7"
              @click="$taskMethods.updateTaskType"
            >
              <u>{{ task.taskType }}</u>
            </a>
            <span v-else class="is-size-7">{{ task.taskType }}</span>
          </td>
        </tr>
        <!-- Updated -->
        <tr>
          <td>Updated</td>
          <td colspan="2">
            <span class="is-size-7">{{
              $moment(task.dateUpdated.toDate()).calendar(null, {
                sameElse: "DD/MM/YYYY \\a\\t HH:mm"
              })
            }}</span>
          </td>
        </tr>
        <!-- Priority -->
        <tr>
          <td>Priority</td>
          <td colspan="2">
            <a class="is-size-7" @click="openPriorityModal">{{
              task.priority
                ? this.$_.capitalize(task.priority.substr(2))
                : "General"
            }}</a>
            <b-icon
              v-if="getPriorityIcon(task.priority)"
              v-bind="getPriorityIcon(task.priority)"
              class="has-margin-left-50"
            />
          </td>
        </tr>
        <!-- Site -->
        <tr v-if="task.siteId">
          <td>Site</td>
          <td :style="{ wordBreak: 'break-word', whiteSpace: 'normal' }">
            <router-link
              :to="`/sites/magic/${task.siteId}`"
              class="is-size-7"
              >{{ task.fqdn }}</router-link
            >
          </td>
          <td>
            <a
              :href="`http://${task.fqdn}`"
              target="_blank"
              class="has-text-grey"
            >
              <b-icon icon="external-link-alt" size="is-small" />
            </a>
          </td>
        </tr>
        <!-- Site notes -->
        <tr v-if="task.siteId && !adminContext">
          <td>Notes</td>
          <td colspan="2">
            <a class="is-size-7" @click="openSiteNotesModal">View site notes</a>
          </td>
        </tr>
        <!-- Site secrets -->
        <tr v-if="task.siteId && !adminContext">
          <td>Secrets</td>
          <td colspan="2">
            <a class="is-size-7" @click="openSiteSecretsModal"
              >View site secrets</a
            >
          </td>
        </tr>
        <!-- Lead agent -->
        <tr v-if="$task.userIsAgent">
          <td>Lead agent</td>
          <td colspan="2">
            <a class="is-size-7" @click="manageAgents"
              ><u>{{ $task.leadAgent }}</u></a
            >
          </td>
        </tr>
        <!-- Team -->
        <tr v-if="$task.userIsAgent">
          <td>Team</td>
          <td colspan="2">
            <p class="is-size-7">{{ $task.team }}</p>
          </td>
        </tr>
        <!-- Cost -->
        <tr v-if="$task.userIsAgent && $task.cost">
          <td>Cost</td>
          <td colspan="2">
            <p class="is-size-7">{{ $task.cost }}</p>
          </td>
        </tr>
        <!-- Plan -->
        <tr v-if="$task.userIsAgent && $_.get($task, `plan.ref`)">
          <td>Plan</td>
          <td colspan="2">
            <router-link
              :to="`/admin/contracts/${task.siteId}`"
              class="has-text-dark is-size-7"
              @click.native.stop
            >
              <u>{{ $_.get($task, `plan.name`) }}</u>
            </router-link>
          </td>
        </tr>
        <!-- Opened by -->
        <tr v-if="$task.userIsAgent && $task.authorEmail">
          <td>Opened by</td>
          <td colspan="2">
            <router-link
              :to="`/admin/users/${task.authorId}`"
              class="has-text-dark is-size-7"
            >
              <u>{{ $task.authorEmail }}</u>
            </router-link>
          </td>
        </tr>
        <!-- Alerts -->
        <tr v-if="$task.userIsParticipant && $task.isOpen">
          <td :style="{ verticalAlign: 'middle' }">
            Alerts
          </td>
          <td colspan="2">
            <b-switch
              v-model="showAlerts"
              class="has-margin-top-25 is-size-7"
              :class="{ 'has-cursor-wait': processing.muteUnmuteAlerts }"
              :disabled="processing.muteUnmuteAlerts"
              type="is-success"
              >{{ showAlerts ? "On" : "Off" }}</b-switch
            >
          </td>
        </tr>
        <!-- Alerts -->
        <tr v-if="adminContext">
          <td>Attention</td>
          <td colspan="3">
            <needs-attention-control :task="task" class="is-size-7" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { getPriorityIcon } from "@src/data/task";
export default {
  name: "TaskDetails",
  components: {
    "needs-attention-control": () =>
      import("@shared/tasks/needsAttentionControl.vue")
  },
  inject: ["$taskProvider", "$taskMethods"],
  props: {
    task: {
      required: true,
      type: Object
    },
    site: {
      required: false,
      type: Object,
      default: null
    },
    adminContext: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processing: {
        muteUnmuteAlerts: false,
        opening: false
      }
    };
  },
  computed: {
    $task() {
      return this.$taskProvider();
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    showAlerts: {
      get() {
        if (!this.$task.userIsParticipant) return false;
        // we negate as UI states 'Show alerts', whis is oposite of mute
        return !this.$_.get(
          this.task,
          `participantsData.${this.userId}.muteAlerts`,
          false
        );
      },
      set(value) {
        // we negate as UI states 'Receive alerts', which is oposite of mute
        this.muteUnmuteAlerts(!value);
      }
    }
  },
  methods: {
    getPriorityIcon,
    manageAgents() {
      const modal = this.$modal.open({
        component: () => import("@shared/tasks/agent/_agentsSelectModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          taskId: this.task._id
        },
        events: {
          close: () => {
            modal.close();
          }
        }
      });
    },
    muteUnmuteAlerts(value) {
      if (!this.$task.userIsParticipant || this.processing.muteUnmuteAlerts)
        return;
      this.processing.muteUnmuteAlerts = true;
      this.$store
        .dispatch("tasks/muteUnmuteAlerts", {
          taskId: this.task._id,
          muteAlerts: value
        })
        .then(() => {
          this.$toast.open({
            message: `Alerts <strong>${
              value ? "disabled" : "enabled"
            }</strong> for task ${this.task.reference}`
          });
        })
        .catch(error => {
          console.error(error);
          this.$toast.open({
            message: `Error setting alerts preference`,
            type: "is-danger"
          });
        })
        .then(() => {
          this.processing.muteUnmuteAlerts = false;
        });
    },
    openSiteNotesModal() {
      if (!this.task.siteId) return;
      this.$modal.open({
        component: () => import("@shared/sites/_siteNotesModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.task.siteId
        }
      });
    },
    openSiteSecretsModal() {
      if (!this.task.siteId) return;
      this.$modal.open({
        component: () => import("@shared/sites/_siteSecretsModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.task.siteId
        }
      });
    },
    openPriorityModal() {
      this.$modal.open({
        component: () => import("@shared/tasks/_taskPriorityModal.vue"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: { taskId: this.task._id }
      });
    },
    async reOpenTask() {
      this.processing.opening = true;
      await this.$taskMethods.reOpenTask();
      this.processing.opening = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.switch .control-label:empty {
  padding-left: 0 !important;
}
.task-participants {
  display: flex;
  justify-content: center;
  picture + picture {
    margin-left: -0.75em;
    z-index: 1;
  }
}
table {
  width: 100%;
  tr td {
    vertical-align: middle;
    &:nth-child(1) {
      font-size: 15px;
      color: $grey;
      white-space: nowrap;
      width: 1%;
    }
    &:nth-child(3) {
      text-align: right;
      white-space: nowrap;
      width: 1%;
    }
    &:nth-child(2) {
      max-width: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    a {
      color: $text;
      text-decoration: underline;
    }
  }
  tr:last-child td {
    border-width: 0;
  }
}
</style>
